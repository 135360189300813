<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card style="margin:-20px;">
                    <div class="profile-tab-container">
                        <div class="profile-intro">
                            <h2>{{this.student.data.name}} <span>({{this.student.data.enroll_code}})</span></h2>
                            <h4>Grade {{this.student.data.grade}} / {{this.student.data.section}} / Roll No
                                {{this.student.data.roll}}</h4>
                        </div>

                        <div class="tab-content">
                            <div class="t-container">
                                <div class="profile-image">
                                    <div :class="'image'">
                                        <img :src="student.data.profile_image"
                                             alt="User Image">
                                        <input type="file"
                                               style="display: none"
                                               ref="image"
                                               accept="image/*"
                                        >
                                    </div>
                                </div>
                                <div class="t-hold">
                                    <v-bottom-nav
                                            :active.sync="activeBtn"
                                            :value="true"
                                            absolute
                                            color="transparent">
                                        <v-btn color="teal"
                                               flat
                                               value="general">
                                            <span>Exam</span>
                                            <v-icon>history</v-icon>
                                        </v-btn>
                                    </v-bottom-nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-flex>
            <div class="profile-contents">

                <v-layout row wrap>
                    <v-flex xs4>
                        <v-card class="mb-2 elevation-1">
                            <v-list>
                                <v-list-tile>
                                    <v-list-tile-action>
                                        <v-icon color="#999">person</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title class="text-xs-right">
                                            <strong>{{student.data.name}}</strong>
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-divider></v-divider>
                                <v-list-tile v-if="true">
                                    <v-list-tile-action>
                                        <v-icon color="#999">cake</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title class="text-xs-right">
                                            {{student.data.personal ? student.data.personal.dob : '-'}}
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-divider/>
                                <v-list-tile v-if="true">
                                    <v-list-tile-action>
                                        <v-icon color="#999">fa-at</v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title class="text-xs-right">
                                            {{student.data.email||'-'}}
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-divider/>
                                <v-list-tile v-if="true">
                                    <v-list-tile-action>
                                        <v-icon color="#999">{{ !student.data.gender ? 'fa-male' :
                                            'fa-female' }}
                                        </v-icon>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title class="text-xs-right">
                                            {{student.data.personal ? student.data.personal.gender : '-'}}
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                        </v-card>
                    </v-flex>

                    <v-flex xs8>
                        <v-card>
                            <table class="v-datatable v-table theme--light">
                                <thead>
                                <tr>
                                    <th class="text-xs-left"><strong>Exam</strong></th>
                                    <th class="text-xs-right"><strong>Action</strong></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(exam, i) in exams">
                                    <tr :key="i" v-if="exam.is_final===1">
                                        <td>{{ exam.name}}</td>
                                        <td class="text-xs-right">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on"
                                                           small icon
                                                           color="primary" class="pa-0 ma-1"
                                                           @click="viewResult(exam.id,false)">
                                                        <v-icon small dark>print</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Generate Annual Terminal Grade Sheet</span>
                                            </v-tooltip>

                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on"
                                                           small icon
                                                           color="warning" class="pa-0 ma-1"
                                                           @click="viewResult(exam.id,exam.is_final)">
                                                        <v-icon small dark>print</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Generate Annual Merged Grade Sheet</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </v-card>
                    </v-flex>
                </v-layout>

            </div>
        </v-layout>

    </v-container>
</template>
<script>
    import Form from '@/library/Form'
    import {mapState, mapActions, mapMutations} from 'vuex'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    // import VueCropper from 'vue-cropperjs';

    export default {
        // ProfileHome, ProfileEnroll, ProfileTransport,
        // components: {VueCropper},
        data: () => ({
            form: new Form({}, '/api/student'),
            activeBtn: 'general',
            exams: [],
            markSheetType: 'grading',


        }),
        watch: {
            'batch': {
                handler() {
                    this.get();
                }
            }
        },
        mounted() {
            this.get();
            this.getExam();
            this.getGradeRatingStatus();
        },
        computed: {
            ...mapState(['student', 'batch'])
        },
        methods: {
            ...mapActions(['setStudent', 'getStudentInfo']),
            get() {
                this.fetch_student_record = false;
                this.getStudentInfo('/api/student/' + this.$route.params.id).then(res => {
                    this.fetch_student_record = true;
                }).catch(err => {
                    if (err.response && err.response.status === 404) {
                        this.$events.fire('notification', {message: "Students not found", status: 'error'});
                    }
                });
            },
            getExam() {
                this.$rest.get('/api/exam').then(res => {
                    this.exams = res.data.data;
                })
            },
            getGradeRatingStatus() {
                // this.$rest.get('api/marksheet-type?gradeId=' + this.student.data.grade_id).then(res => {
                //     this.markSheetType = res.data.marksheet_type;
                // })
            },
            viewResult(examId, isFinal) {
                var printType = 'terminal';
                if (isFinal) {
                    printType = 'annual';
                }
                this.$rest.get('/api/print/mark-sheet').then(({data}) => {
                    let url = data.data.download_url + '?examId=' + examId + '&sectionId=' + this.student.data.section_id + '&enrollId=' + this.student.data.enroll_id + '&issueDate=' + nd.format('YYYY-MM-DD') + '&print_type=' + printType;

                    if (this.markSheetType === 'rating')
                        url += '&marksheet_type=rating';

                    window.open(url);
                })
            },
        }
    }
</script>

<style lang="scss">
    .user_add_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }

    .v-item-group.v-bottom-nav {
        box-shadow: none;
    }

    .v-list > div:hover {
        cursor: pointer;
        background-color: #eee;
    }

    .update-form-profile {
        h2 {
            color: #777;
            margin-bottom: 8px;
        }
    }

    .profile-contents {
        width: 100%;
        margin: 26px auto;
    }

    .delete-alert-box {
        /*border-top: 5px solid red!important;*/
        box-shadow: 0 -5px 0 #df3030 !important;
        margin-top: 10px;
        padding: 50px 60px !important;

        .v-text-field {
            margin-left: -10px !important;
        }

        h1 {
            font-size: 30px;
            color: #666;
        }

        p {
            color: #666;
        }

        strong {
            color: #555;
        }
    }
</style>
